const initialState = {
    isLoaded: false,
}

export function appSettingsReducer(state = initialState, action) {
    switch (action.type) {
        case 'IS_LOADED':
            return { ...state, isLoaded: !state.isLoaded }
        default:
            return state
    }
}

