const variantImg = {
  visible: {
    opacity: 1,
  },
  hidden: {
    opacity: 0,
    transition: {
      duration: 3,
    },
  },
};

const variantBtn = {
  visible: {
    opacity: 0,
  },
  hidden: {
    opacity: 1,
  },
};



// const father = {
//   hidden: {
//     opacity: 0,
//   },
//   visible: {
//     opacity: 1,
//     transition: {
//       duration: 1.5,
//       staggerChildren: 0.2,
//     },
//   },
// };

// const childOne = {
//   hidden: {
//     opacity: 0,
//     y: -150,
//   },
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       duration: 0.7,
//     },
//   },
// };

// const childTwo = {
//   hidden: {
//     opacity: 0,
//     y: -100,
//   },
//   visible: {
//     opacity: 1,
//     y: 0,
//     transition: {
//       duration: 0.7,
//     },

//   },
// };

// const childThree = {
//   hidden: {
//     opacity: 0,
//   },
//   visible: {
//     opacity: 1,
//     transition: {
//       duration: 0.7,
//     },

//   },
// };

export const variantService = {
  variantImg,
  variantBtn,
  // father,
  // childOne,
  // childTwo,
  // childThree

};
