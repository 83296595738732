import { motion } from 'framer-motion'
// import { useEffect, useState } from 'react'
import { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { variantService } from '../services/variantFMService'
import { loadProjects } from '../store/actions/fmProjectsActions'





export const _AppFrontendMentor = (props) => {

    const dispatch = useDispatch()

    const projects = useSelector(state => state.fmProjectsModule.fmProjects)

    // const [showFM, setShowFM] = useState(false)


    useEffect(() => {
        dispatch(loadProjects())
    }, [dispatch])

    // useEffect(() => {

    //     window.addEventListener('scroll', handleScroll)

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }

    // }, [])



    // const handleScroll = () => {

    //     // console.log(window.pageYOffset);

    //     if (window.pageYOffset > 1100) {
    //         setShowFM(true)
    //     }

    // }




    return (
        // <motion.div className="app-frontend-mentor" variants={variantService.father} initial='hidden' animate={showFM ? 'visible' : 'hidden'} style={{
        //     visibility: `${!showFM ? 'hidden' : 'visible'}`
        // }}>
        <div className="app-frontend-mentor">
            <div className="header">Frontend Mentor Projects</div>
            <div className="content container">
                {/* <motion.h1 className="text-center" variants={variantService.childOne}>Frontend Mentor Projects</motion.h1> */}
                {/* <h1 className="text-center">Frontend Mentor Projects</h1> */}

                {/* <motion.p className="text-center" variants={variantService.childTwo}>I am a big fan of working on css/scss stuff and I love challenging myself with difficult designs. <br /> Here is an exaple of Pixel Perfect projects that I did from Frontend Mentor Website with a mobile support (400px). </motion.p> */}
                <p className="text-center">I am a big fan of working on css/scss stuff and I love challenging myself with difficult designs. <br /> Here is an exaple of Pixel Perfect projects that I did from Frontend Mentor Website with a mobile support (400px). </p>

                <div className="box-holder">
                    {projects.map(project =>
                        <div key={project.name} className="box-placer" >
                            <motion.div className="box" initial='visible' whileHover='hidden'>
                                {/* <motion.div className="box" variants={variantService.childThree} > */}

                                <motion.div className="img-holder" variants={variantService.variantImg}>
                                    {/* <motion.div className="img-holder" whileHover={{ opacity: 0 }} initial={{ opacity: 1 }}> */}
                                    <img src={project.imgUrl} alt="" />
                                    <div className="tags">
                                        <div className="tag-holder">
                                            {project.tags.map(tag =>
                                                <div className={`${tag.tagClass} flex auto-center`} key={tag.tId}>
                                                    <div className="tag-txt">{tag.name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>

                                <motion.div className="btn-holder" variants={variantService.variantBtn} >
                                    {/* <motion.div className="btn-holder" whileHover={{ opacity: 1 }} initial={{ opacity: 0 }}> */}
                                    <div className="btn-box">
                                        <div className="btn-box-holder">
                                            <p>Frontend Mentor - {project.name}</p>
                                            <a href={project.link} target="_blank" rel="noreferrer"><button>VIEW PROJECT</button></a>
                                        </div>
                                    </div>
                                </motion.div>

                            </motion.div>

                        </div>
                    )}

                </div>

            </div>

        </div>
        // </motion.div>
    )
}


const mapDispatchToProps = {
}

export const AppFrontendMentor = connect(null, mapDispatchToProps)(_AppFrontendMentor)