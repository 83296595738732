import React from 'react';
import './assets/styles/styles.scss';
import { Home } from './pages/Home';

export class App extends React.Component {
  render() {
    return (
      <main>
        <Home />
      </main>
    );
  }
}
