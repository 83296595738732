const initialState = {
  fmProjects: [],
};

export function fmProjectsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_PROJECTS':
      return { ...state, fmProjects: action.fmProjects };
    default:
      return state;
  }
}
