import {
  createStore, applyMiddleware, combineReducers, compose,
} from 'redux';
import thunk from 'redux-thunk';
import { fmProjectsReducer } from './reducers/fmProjectsReducer';
import { portfolioProjectsReducer } from './reducers/portfolioProjectsReducer';
import { skillsReducer } from './reducers/skillsReducer';
import { bigProjectsReducer } from './reducers/bigProjectsReducer';
import { appSettingsReducer } from './reducers/appSettingsReducer'

const rootReducer = combineReducers({
  fmProjectsModule: fmProjectsReducer,
  portfolioProjectsModule: portfolioProjectsReducer,
  skillsModule: skillsReducer,
  bigProjectsModule: bigProjectsReducer,
  appSettingsModule: appSettingsReducer,


});

const composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;

export const store = createStore(rootReducer, composeEnhancers(applyMiddleware(thunk)));
