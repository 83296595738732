import { skillsService } from '../../services/skillsService';

export function loadSkills() {
  return async (dispatch) => {
    try {
      const skills = await skillsService.query();
      dispatch({ type: 'SET_SKILLS', skills });
    } catch (err) {
      console.log('skillsActions: Could not load skills', err);
    }
  };
}
