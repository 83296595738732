import Mike from '../assets/img/mikemavoWeb.png';

export const portfolioProjectsService = {
    query,
};

async function query() {
    const projects = [
        {
            name: 'mikemavo',
            imgUrl: Mike,
            link: 'https://mikemavo.com',
            linkName: 'mikemavo.com',
            desc:'Michael Mavromatis is a 3D artist who worked on short films , VR , game animations and interior design . He is also a famous Dota2 youtuber with over 260k subscribers and counting ',
            tags:[
                {
                  tId:'p101t101',
                  name:'React',
                  tagClass:'tag-one'
                },
                {
                  tId:'p101t102',
                  name:'Sass',
                  tagClass:'tag-two'
                },
                {
                  tId:'p101t103',
                  name:'Firebase',
                  tagClass:'tag-three'
                },
                {
                  tId:'p101t104',
                  name:'React-hooks',
                  tagClass:'tag-four'
                },
                {
                  tId:'p101t105',
                  name:'Youtube API',
                  tagClass:'tag-five'
                },
              ],
        },

    ];
    return projects;
}
