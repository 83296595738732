const initialState = {
  skills: [],
};

export function skillsReducer(state = initialState, action) {
  switch (action.type) {
    case 'SET_SKILLS':
      return { ...state, skills: action.skills };
    default:
      return state;
  }
}
