import Dine from '../assets/img/dine.png';
import Fylo from '../assets/img/Fylo.png';
import Myteam from '../assets/img/myteam.png';

export const fmProjectsService = {
  query,
};

async function query() {
  const projects = [
    {
      imgUrl: Dine,
      link: 'https://andreydobrinski.github.io/dine/#/',
      name: 'dine',
      tags: [
        {
          tId: 'p101t101',
          name: 'React',
          tagClass: 'tag-one'
        },
        {
          tId: 'p101t102',
          name: 'Sass',
          tagClass: 'tag-two'
        },
        {
          tId: 'p101t103',
          name: 'Github',
          tagClass: 'tag-three'
        },
        {
          tId: 'p101t104',
          name: 'Figma',
          tagClass: 'tag-four'
        },
      ],
    },
    {
      imgUrl: Fylo,
      link: 'https://andreydobrinski.github.io/fylo/#/',
      name: 'Fylo',
      tags: [
        {
          tId: 'p102t101',
          name: 'React',
          tagClass: 'tag-one'
        },
        {
          tId: 'p102t102',
          name: 'Sass',
          tagClass: 'tag-two'
        },
        {
          tId: 'p102t103',
          name: 'Github',
          tagClass: 'tag-three'
        },
        {
          tId: 'p102t104',
          name: 'Figma',
          tagClass: 'tag-four'
        },
      ],
    },
    {
      imgUrl: Myteam,
      link: 'https://andreydobrinski.github.io/myTeam/#/',
      name: 'myteam',
      tags: [
        {
          tId: 'p103t101',
          name: 'React',
          tagClass: 'tag-one'
        },
        {
          tId: 'p103t102',
          name: 'Sass',
          tagClass: 'tag-two'
        },
        {
          tId: 'p103t103',
          name: 'Github',
          tagClass: 'tag-three'
        },
        {
          tId: 'p103t104',
          name: 'Figma',
          tagClass: 'tag-four'
        },
      ],
    },

  ];
  return projects;
}
