export const bigProjectsService = {
  query,
};

async function query() {
  const projects = [
    {
      id: 'p101',
      desc: 'PaweSome is a market style website for people to find thier best friend. The website was created with React and published at Heroku , it also includes features such as Dark Mode and live chat (socket.io) ',
      link: 'https://pawesome.onrender.com/#/',
      tags:[
        {
          tId:'p101t101',
          name:'React'
        },
        {
          tId:'p101t102',
          name:'Sass'
        },
        {
          tId:'p101t103',
          name:'Heroku'
        },
        {
          tId:'p101t104',
          name:'Socket.io'
        },
        {
          tId:'p101t105',
          name:'MongoDB'
        },
      ],
    },
    {
      id: 'p102',
      desc: 'Apexgram is a copy of Instagram that was made for study purpose . Here you can sign up and add comments to people posts.You can also chat with anyone that you would like(comming soon). The website was created using React hooks , Node.js , Redux ,framer motion(animation) , socket.io and published to Heroku .',
      link: 'https://apexgram.onrender.com/',
      tags:[
        {
          tId:'p102t101',
          name:'React'
        },
        // {
        //   tId:'p102t102',
        //   name:'React-hooks'
        // },
        {
          tId:'p102t103',
          name:'Sass'
        },
        {
          tId:'p102t104',
          name:'Heroku'
        },
        {
          tId:'p102t105',
          name:'Framer motion'
        },
        {
          tId:'p102t106',
          name:'Socket.io'
        },
        {
          tId:'p102t107',
          name:'MongoDB'
        },

      ],
    },
    {
      id: 'p103',
      desc: 'APPSUS is a copy of Google apps which includes various apps such as: Email - creating and deleting emails . Keep - making notes , lists , photos and ect . Books - find your favorite book and add it to the list!',
      link: 'https://andreydobrinski.github.io/AppSus/#/',
      tags:[
        {
          tId:'p103t101',
          name:'React'
        },
        {
          tId:'p103t102',
          name:'CSS'
        },
        {
          tId:'p103t103',
          name:'Bootstrap'
        },
        {
          tId:'p103t104',
          name:'Github'
        },
        {
          tId:'p103t105',
          name:'Node'
        },

      ],
    },

  ];
  return projects;
}
