import { AppHero } from '../cmps/AppHero'
import { AppAbout } from '../cmps/AppAbout'
import { AppFrontendMentor } from '../cmps/AppFrontendMentor'
import { AppProjects } from '../cmps/AppProjects'
import { AppPortfolios } from '../cmps/AppPortfolios'
import { AppSkills } from '../cmps/AppSkills'
import { AppFooter } from '../cmps/AppFooter'
import { useEffect } from 'react'
import Loader from 'react-loader-spinner'
import AppWebAndApp from '../cmps/AppWebAndApp'
import AppReactNative from '../cmps/AppReactNative'
import { useDispatch, useSelector } from 'react-redux'
import { toggleLoad } from '../store/actions/appSettingsActions'


export const Home = (props) => {

  // const [bgIsLoaded, setBgIsLoaded] = useState(false)
  // const [showAbout, setshowAbout] = useState(false)
  const dispatch = useDispatch()

  const { isLoaded } = useSelector(state => state.appSettingsModule)

  useEffect(() => {

    // setTimeout(() => {
    //   setBgIsLoaded(true)
    // }, 1000);

    setTimeout(() => {
      dispatch(toggleLoad(true))
    }, 3000);



    // window.addEventListener('scroll', handleScroll)


  }, [dispatch])

  // const handleScroll = () => {

  //   console.log(window.pageYOffset);

  //   if (window.pageYOffset > 500) {
  //     setshowAbout(true)
  //   }

  // }

  return (
    <div className="app-home">
      {/* {!bgIsLoaded && <div className="loader flex justify-center align-center"><Loader type="TailSpin" color="black" height={100} width={100} /></div>} */}
      {!isLoaded && <div className="loader flex justify-center align-center"><Loader type="TailSpin" color="black" height={100} width={100} /></div>}
      <div className="content" style={{
        // display: `${!bgIsLoaded ? 'none' : 'block'}`
        display: `${!isLoaded ? 'none' : 'block'}`
      }}>

        <AppHero />
        <AppAbout />
        <AppWebAndApp/>
        <AppFrontendMentor />
        <AppProjects />
        <AppReactNative/>
        <AppPortfolios />
        <AppSkills />
        <AppFooter />
      </div>
    </div>
  )
}