import { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { loadSkills } from '../store/actions/skillsActions'

export const _AppSkills = (props) => {

    const dispatch = useDispatch()

    const { skills } = useSelector(state => state.skillsModule)

    useEffect(() => {
        dispatch(loadSkills())
    }, [dispatch])

    return (
        <section className="app-skills">
            <div className="header">SKILLS</div>
            {skills.map(skill =>
                <div key={skill.title} className="skills-container flex justify-center align-center">
                    <h1>{skill.title}</h1>
                    <div className="skills ">
                        {skill.icons.map(icon =>
                            <div className="skills-one " key={icon.name}>
                                <div className="skill-img">
                                    <img src={icon.img} alt="" />
                                </div>
                                <p className="text-center">{icon.name}</p>
                            </div>
                        )}
                    </div>

                </div>
            )}
        </section>
    )
}


const mapDispatchToProps = {
}

export const AppSkills = connect(null, mapDispatchToProps)(_AppSkills)