import React from 'react'

import Apexgram from '../assets/img/Apexgram.jpg';

export default function AppWebAndApp() {
    return (
        <div className='app-web-and-app'>
            <div className="header">Cross Platform Projects</div>
            <div className="content container">

                {/* <h1>Cross Platform Projects</h1> */}

                <h2>Apexgram</h2>
                <div className='img-holder flex auto-center'>
                    <img src={Apexgram} alt="" />
                </div>
                <p>Apexgram is essentially a pixel perfect version of Instagram which has a web version and a mobile application version of Apexgram.</p>
                <p>Apexgram web version was made using technologies such as: React, Scss, Heroku, MongoDB, Node.js, Redux, Framer Motion and socket.io. <br />
                    You can visit the website version here: <a target="_blank" rel="noreferrer" href="https://apexgram.onrender.com/" >https://apexgram.onrender.com/</a> </p>
                <p>Apexgram mobile version was made using technologies such as: React Native , Css Stylesheet, MongoDB, Node.js, Redux, socket.io and Firebase. <br />
                    If you are interested in seeing the project , feel free to contact me.</p>

            </div>
        </div>
    )
}
