const initialState = {
    projects: [],
  };
  
  export function portfolioProjectsReducer(state = initialState, action) {
    switch (action.type) {
      case 'SET_PORTFOLIO_PROJECTS':
        return { ...state, projects: action.projects };
      default:
        return state;
    }
  }
  