
import { ReactComponent as LinkedIn } from "../assets/icon/linkedin.svg"
import { ReactComponent as Github } from "../assets/icon/github.svg"


export const AppFooter = () => {
  return (
    <div className="app-footer">

      <div className="contact-header flex auto-center column">
        <h1>let's connect</h1>
        <p>i can be of service to you or maybe just to chat</p>
      </div>

      <div className="contact1 ">
        <h1> <span>phone:</span> +972542103930</h1>
        <h1> <span>email:</span> apdrushk@gmail.com</h1>
        <div className="links flex auto-center">
          <a href="https://www.linkedin.com/in/andreydobrinski/" target="_blank" rel="noreferrer" className="link"><LinkedIn /></a>
          <a href="https://github.com/AndreyDobrinski" target="_blank" rel="noreferrer" className="link"><Github /></a>
        </div>
      </div>

      <div className="contact2 flex auto-center">
        <a href="https://drive.google.com/file/d/1GMPg4pGL4W8HDPGMEAtTxazSLxcgJqA_/view?usp=sharing" target="_blank" rel="noreferrer">
          <button>view resume</button>
        </a>
      </div>

    </div>
  )

}
