// import { Component } from 'react'
import { motion } from 'framer-motion'
// import BackgroundImageOnLoad from 'background-image-on-load';
// import Loader from 'react-loader-spinner'

import { variantService } from '../services/variantHeroService'
import { AppSvg } from '../cmps/AppSvg'
import {  useSelector } from 'react-redux'
// import { useEffect } from 'react'
// import { toggleLoad } from '../store/actions/appSettingsActions'

// import imgOne from '../assets/img/1.png'
// import { useState } from 'react';



export const AppHero = () => {

    // const dispatch = useDispatch()

    const { isLoaded } = useSelector(state => state.appSettingsModule)

    // useEffect(() => {
    //     dispatch(toggleLoad(true))
    //     console.log();
    // }, [dispatch])


    if (!isLoaded) return <div>Loading...</div>
    return (
        <div className="hero-container">
            {/* {!bgIsLoaded && <div className="loader flex justify-center align-center"><Loader type="TailSpin" color="black" height={100} width={100} /></div>} */}
            <motion.div variants={variantService.father} initial='hidden' animate='visible' >

                {/* <div className="hero" style={{
                    visibility: `${!bgIsLoaded ? 'hidden' : 'visible'}`
                }}> */}
                <div className="hero">

                    {/* <BackgroundImageOnLoad
                        src={imgOne}
                        onLoadBg={async () =>
                            await setBgIsLoaded(true)
                        }
                        onError={err => console.log('error', err)}
                    /> */}

                    <motion.div variants={variantService.childOne}></motion.div>
                    <motion.div className="text flex auto-center" variants={variantService.childTwo}>Hi</motion.div>
                    <motion.div className="text flex auto-center" variants={variantService.childThree}>My name is <br /> Andrey Dobrinski</motion.div>
                    <motion.div className="heroTwo" variants={variantService.childFour}>
                        <div className="content">
                            <motion.div variants={variantService.scrollVariant}>scroll to continue</motion.div>
                        </div>
                        <AppSvg />
                    </motion.div>
                </div>
            </motion.div>
        </div>)
}