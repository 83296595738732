const variantImg = {
  visible: {
    opacity: 1,
    y: 0,
  },
  hidden: {
    opacity: 0,
    y: -150,
    transition: {
      type: 'spring',
      stiffness: 100,
      duration: 0.2,
    },
  },
};

const variantDetails = {
  hidden: {
    opacity: 1,
    y: 0,
  },
  visible: {
    opacity: 0,
    y: 257,
    transition: {
      duration: 1,
    },
  },
};

export const variantService = {
  variantImg,
  variantDetails,
};
