import { fmProjectsService } from '../../services/fmProjectsService';

export function loadProjects() {
  return async (dispatch) => {
    try {
      const fmProjects = await fmProjectsService.query();
      dispatch({ type: 'SET_PROJECTS', fmProjects });
    } catch (err) {
      console.log('fmProjectsActions: Could not load Projects', err);
    }
  };
}
