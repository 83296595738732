import { bigProjectsService } from '../../services/bigProjectsService';

export function loadBigProjects() {
  return async (dispatch) => {
    try {
      const projects = await bigProjectsService.query();
      dispatch({ type: 'SET_BIG_PROJECTS', projects });
    } catch (err) {
      console.log('bigProjectsActions: Could not load Projects', err);
    }
  };
}
