
import myFace from '../assets/img/myFace.JPG'
// import { motion } from 'framer-motion'
// import { useEffect, useState } from 'react';

// const father = {
//     hidden: {
//         opacity: 0,
//     },
//     visible: {
//         opacity: 1,
//         transition: {
//             duration: 1.5,
//         },
//     },
// };

// const childOne = {
//     hidden: {
//         x: -150,
//     },
//     visible: {
//         x: 0,
//         transition: {
//             duration: 1,
//         },
//     },
// };

// const childTwo = {
//     hidden: {
//         x: 150,
//     },
//     visible: {
//         x: 0,
//         transition: {
//             duration: 1,
//         },

//     },
// };


// THE PROBLEM: need to find a way how to start animate when its scrolled to 500 . SOLVED
// THE PROBLEM: child one and child two are not working . SOLVED (forgot to add .motion)


export const AppAbout = () => {

    // const [showAbout, setshowAbout] = useState(false)


    // useEffect(() => {

    //     window.addEventListener('scroll', handleScroll)

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }

    // }, [])



    // const handleScroll = () => {

    //     // console.log(window.pageYOffset);

    //     if (window.pageYOffset > 500) {
    //         setshowAbout(true)
    //     }

    // }

    return (
        // <motion.section className="app-about" variants={father} initial='hidden' animate={showAbout ? 'visible' : 'hidden'} style={{
        //     visibility: `${!showAbout ? 'hidden' : 'visible'}`
        // }} >
        <section className="app-about">
            {/* <motion.div className="left_wrapper" variants={childOne}> */}
            <div className="left_wrapper">
                <div className="border_deisgn"></div>
                <div className="about-inner">
                    <div className="about-header">
                        ABOUT ME
                        </div>
                    <div className="about-content">
                        I am a Full-Stack developer who loves to create beautiful websites. I love to learn and play around with different technologies in order to create amazing products.
                        </div>
                </div>
            </div>
            {/* </motion.div> */}

            {/* <motion.div className="right_wrapper flex auto-center column" variants={childTwo}> */}
            <div className="right_wrapper flex auto-center column" >
                <div className="img-holder">
                    <img src={myFace} alt="" />
                </div>
            </div>
            {/* </motion.div> */}
        </section>
        // </motion.section>
    )
}
