import AngularIcon from '../assets/img/angular.png';
import ReactIcon from '../assets/img/react-logo.png';
import VueIcon from '../assets/img/vue-logo.png';
import NextIcon from '../assets/icon/Nextjs.svg';

import JavascriptIcon from '../assets/img/javascript-icon.png';
import jQueryIcon from '../assets/img/jquery-icon.png';
import NodeIcon from '../assets/img/nodejs-icon.png';
import EsSixIcon from '../assets/img/es6-icon.png';
import ReduxIcon from '../assets/img/redux.png';
import ReactHoocksIcon from '../assets/img/react-hooks.png';
import ReactNativeIcon from '../assets/img/react-native.png';
import TypescriptIcon from '../assets/img/typescript.png';
import ReactTypeIcon from '../assets/img/react-typescript.png';

import CssIcon from '../assets/img/css3-icon.png';
import SassIcon from '../assets/img/sass-icon.png';
import BootstrapIcon from '../assets/img/bootstrap-icon.png';
import MotionIcon from '../assets/img/frame-motion-icon.png';

import GithubIcon from '../assets/img/github-icon.png';
import HerokuIcon from '../assets/img/heroku-icon.png';
import MongoDBIcon from '../assets/img/mongodb-icon.png';
import Html5Icon from '../assets/img/html5-icon.png';
import FigmaIcon from '../assets/img/figma2.png';
import FirebaseIcon from '../assets/img/firebase.png';
import VercelIcon from '../assets/icon/vercel.svg';
import GraphQLIcon from '../assets/img/GraphQL.png';
import ApolloLIcon from '../assets/img/apollo.png';
import RenderLIcon from '../assets/img/render.png';
import SQLIcon from '../assets/img/sql.png';
import BashIcon from '../assets/img/bash.png';


export const skillsService = {
  query,
};

async function query() {
  const skills = [
    {
      title: 'Frameworks',
      icons: [
        {
          img: AngularIcon,
          name: 'Angular',
        },
        {
          img: ReactIcon,
          name: 'React',
        },
        {
          img: VueIcon,
          name: 'Vue',
        },
        {
          img: ReactNativeIcon,
          name: 'React Native',
        },
        {
          img: NextIcon,
          name: 'Next.js',
        },
      ],
    },
    {
      title: 'Javascript',
      icons: [
        {
          img: JavascriptIcon,
          name: 'Javascript',
        },
        {
          img: jQueryIcon,
          name: 'jQuery',
        },
        {
          img: NodeIcon,
          name: 'Node',
        },
        {
          img: EsSixIcon,
          name: 'ES6',
        },
        {
          img: ReduxIcon,
          name: 'Redux + toolkit',
        },
        {
          img: ReactHoocksIcon,
          name: 'React Hooks',
        },
        {
          img: TypescriptIcon,
          name: 'Typescript',
        },
        {
          img: ReactTypeIcon,
          name: 'React Typescript',
        },
      ],
    },
    {
      title: 'CSS',
      icons: [
        {
          img: CssIcon,
          name: 'CSS3',
        },
        {
          img: SassIcon,
          name: 'SASS',
        },
        {
          img: BootstrapIcon,
          name: 'Bootstrap',
        },
        {
          img: MotionIcon,
          name: 'Framer motion',
        },
      ],
    },
    {
      title: 'Other',
      icons: [
        {
          img: GithubIcon,
          name: 'Github',
        },
        {
          img: HerokuIcon,
          name: 'Heroku',
        },
        {
          img: MongoDBIcon,
          name: 'MongoDB',
        },
        {
          img: Html5Icon,
          name: 'HTML5',
        },
        {
          img: FigmaIcon,
          name: 'Figma',
        },
        {
          img: FirebaseIcon,
          name: 'Firebase',
        },
        {
          img: VercelIcon,
          name: 'Vercel',
        },
        {
          img: GraphQLIcon,
          name: 'GraphQL',
        },
        {
          img: ApolloLIcon,
          name: 'Apollo GraphQL',
        },
        {
          img: RenderLIcon,
          name: 'Render',
        },
        {
          img: SQLIcon,
          name: 'SQL',
        },
        {
          img: BashIcon,
          name: 'Bash script',
        },
      ],
    },
  ];
  return skills;
}
