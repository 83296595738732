import { motion } from 'framer-motion'


import { variantService } from '../services/variantHeroService'


export const AppSvg = (props) => {

        return (
            <motion.svg variants={variantService.svgVariant} initial='hidden' animate='visible' version="1.1" id="Layer_1" x="0px" y="0px"
            viewBox="0 0 1920 1080" enableBackground="new 0 0 1920 1080" >
            <g id="poly-container">
                <motion.rect variants={variantService.pathVariantOne} id="XMLID_6_" x="677.8" y="361" fill="#2B2B2B" width="681.1" height="21.6" />
                <motion.rect variants={variantService.pathVariantOne} id="XMLID_8_" x="677.8" y="788.2" fill="#2B2B2B" width="681.1" height="21.6" />
                <motion.rect variants={variantService.pathVariantTwo} id="XMLID_9_" x="677.8" y="371.8" fill="#2B2B2B" width="21.6" height="427.2" />
                <motion.polygon variants={variantService.pathVariantThree} id="XMLID_11_" fill="#2B2B2B" points="1337.2,788.2 1337.2,752.5 1358.9,749.8 1358.9,800.2 " />
                <polygon id="XMLID_10_" fill="#2B2B2B" points="1337.2,416.5 1337.2,367.4 1358.9,367.4 1358.9,410 " />

            </g>
            <g id="i_x5F_g">
                <path id="i" fill="#FCFCFC" d="M602,452.9v79.4h20.3V543h-52v-10.7h20.1v-79.4h-20.1v-10.6h52v10.6H602z" />
            </g>
            <g id="XMLID_12_">
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_73_" fill="none" stroke="none" strokeMiterlimit="10" d="M841.7,448.3c0,21.6-15.8,36.3-39.3,36.3h-30.5
            V412h31.3C826.1,412,841.7,426.7,841.7,448.3z M822.3,448.5c0-12.5-8.2-21-19.7-21h-11.9v41.8h12.8
            C814.6,469.3,822.3,460.9,822.3,448.5z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_76_" fill="none" stroke="none" strokeMiterlimit="10" d="M904,461.5h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C895.3,428,905.3,441.1,904,461.5z M886.2,451.7c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H886.2z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_79_" fill="none" stroke="none" strokeMiterlimit="10" d="M933.1,440.6c-3.1,0-5.3,1.1-5.3,3.5
            c0,7.3,29.4,3.8,29.3,23.3c0,11.5-10,18-23.7,18c-9.4,0-18.4-2.7-25.3-8l5.8-11.9c6.4,4.5,13.7,6.9,19.9,6.9c3.4,0,5.9-1.1,5.9-3.4
            c0-7.7-28.9-3.8-28.9-23.2c0-11.7,9.8-18.1,23-18.1c8.1,0,16.2,2.3,23.3,6.5l-6.2,12C944,442.7,937.6,440.6,933.1,440.6z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_81_" fill="none" stroke="none" strokeMiterlimit="10" d="M985.4,428.6v56H967v-56H985.4z M984.6,412.9
            c0,4.9-3.5,8.4-8.4,8.4s-8.4-3.5-8.4-8.4c0-5,3.5-8.5,8.4-8.5S984.6,407.9,984.6,412.9z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_84_" fill="none" stroke="none" strokeMiterlimit="10" d="M1053.1,428.6v51c0,15.8-12.3,25.8-30.4,25.8
            c-9.8,0-18.7-3.1-25-7.2l6.4-12.7c5.1,3.5,11.3,5.5,17.3,5.5c8.4,0,13.5-4.2,13.5-10.8v-6c-3.6,4.8-9,7.3-15.9,7.3
            c-14,0-23.9-10.8-23.9-27c0-15.9,9.6-26.5,23.5-26.5c7,0,12.6,2.7,16.4,7.5v-6.8H1053.1z M1034.7,455c0-7.9-4.6-13.1-11.3-13.1
            c-6.8,0-11.4,5.4-11.4,13.1c0,7.7,4.6,13,11.4,13C1030.2,468,1034.7,462.7,1034.7,455z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_87_" fill="none" stroke="none" strokeMiterlimit="10" d="M1123.7,449.3v35.3h-18.3v-30
            c0-6.3-3.7-10.2-9.5-10.2c-6.2,0.1-10.2,4.3-11,10.7v29.6h-18.4v-56h18.4v9.1c3.9-6.4,10.4-9.8,18.5-9.8
            C1115.7,427.9,1123.7,436.2,1123.7,449.3z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_89_" fill="none" stroke="none" strokeMiterlimit="10" d="M841.7,572.5c0,21.6-15.8,36.3-39.3,36.3h-30.5
            v-72.6h31.3C826.1,536.3,841.7,551,841.7,572.5z M822.3,572.7c0-12.5-8.2-21-19.7-21h-11.9v41.8h12.8
            C814.6,593.5,822.3,585.1,822.3,572.7z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_92_" fill="none" stroke="none" strokeMiterlimit="10" d="M904,585.7h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C895.3,552.2,905.3,565.3,904,585.7z M886.2,575.9c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H886.2z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_95_" fill="none" stroke="none" strokeMiterlimit="10" d="M923.2,552.8l11.7,38.9l11.8-38.9h18.4l-21,56
            h-18.9l-20.9-56H923.2z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_97_" fill="none" stroke="none" strokeMiterlimit="10" d="M1022.4,585.7h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C1013.7,552.2,1023.7,565.3,1022.4,585.7z M1004.6,575.9c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H1004.6z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_100_" fill="none" stroke="none" strokeMiterlimit="10" d="M1050.9,532v76.8h-18.4V532H1050.9z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_102_" fill="none" stroke="none" strokeMiterlimit="10" d="M1122.1,580.8c0,17.3-12.2,28.7-30.6,28.7
            s-30.5-11.4-30.5-28.7c0-17.2,12.1-28.6,30.5-28.6S1122.1,563.6,1122.1,580.8z M1079.4,581c0,8.4,4.9,14,12,14
            c7.2,0,12.1-5.6,12.1-14c0-8.3-4.9-13.9-12.1-13.9C1084.3,567.1,1079.4,572.7,1079.4,581z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_105_" fill="none" stroke="none" strokeMiterlimit="10" d="M1193,581.1c0,17.1-10.1,28.4-25.7,28.4
            c-7.1,0-12.9-2.8-16.9-7.9v27.3H1132v-76.1h18.4v7.2c3.9-5.1,9.5-7.9,16.5-7.9C1182.5,552.2,1193,563.7,1193,581.1z M1174.5,580.7
            c0-8.3-4.9-14-12.1-14c-7,0-11.9,5.7-11.9,14s4.9,13.9,11.9,13.9C1169.6,594.5,1174.5,588.9,1174.5,580.7z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_108_" fill="none" stroke="none" strokeMiterlimit="10" d="M1287.7,612c-3.6-3.5-7-6.7-10.1-9.8
            c-6,4.8-13,7.5-20.5,7.5c-14.3,0-24.8-8.8-24.8-21.1c0-9.7,6.6-15.2,14.3-19.4c-4.3-6.1-6-10.9-6-16c0-10.8,8.1-17.6,20.8-17.6
            c12.3,0,20.9,6.7,20.9,16.4c0,10.1-8.4,15.4-16.9,19.9c2.9,3.3,6.5,7,11,11.4c2-3.4,3.5-7.2,4.8-11.5l11.7,8.5
            c-1.8,4.7-3.9,8.9-6.5,12.6c3.3,3.1,6.8,6.5,10.8,10.1L1287.7,612z M1268,592.9c-5.9-5.8-10.6-10.7-14.4-14.9
            c-3.8,2.5-6.4,5.3-6.4,9.1c0,5.7,4.6,9.5,10.6,9.5C1261.4,596.6,1264.8,595.2,1268,592.9z M1258.4,563c5.4-2.9,9.4-6,9.4-10.9
            c0-3.6-2.5-6.1-6.3-6.1c-4.1,0-6.7,3-6.7,7C1254.8,556,1255.9,559.1,1258.4,563z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_112_" fill="none" stroke="none" strokeMiterlimit="10" d="M806.3,676c-11.2,0-19.7,8.8-19.7,20.4
            c0,11.7,8.5,20.6,19.7,20.6c6,0,12.9-3.3,18-8.5l11.1,11.9c-7.8,8.2-19.4,13.5-30.1,13.5c-21.8,0-38.1-15.9-38.1-37.3
            c0-20.9,16.7-36.6,38.8-36.6c10.8,0,22,4.8,29.3,12.4l-11,13.1C819.6,679.7,812.6,676,806.3,676z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_114_" fill="none" stroke="none" strokeMiterlimit="10" d="M879.9,676.3v16.9c-10.2-1-17.1,3.5-18,11.3V733
            h-18.4v-56h18.4v9.3C865.7,679.9,872,676.3,879.9,676.3z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_116_" fill="none" stroke="none" strokeMiterlimit="10" d="M940.9,709.9h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C932.2,676.4,942.1,689.5,940.9,709.9z M923.1,700.1c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H923.1z"
                />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_119_" fill="none" stroke="none" strokeMiterlimit="10" d="M1000.2,696.6V733h-18.1v-6.3
            c-3.6,4.7-9.3,7.1-16.9,7.1c-12,0-19.1-7.2-19.1-17.5c0-10.7,7.6-16.8,21.4-17h14.6v-0.6c0-5.3-3.6-8.4-10.7-8.4
            c-4.7,0-10.8,1.6-16.8,4.5l-5.1-12.4c8.8-3.9,16.9-6.1,26.1-6.1C991.1,676.3,1000.1,683.7,1000.2,696.6z M982.1,714.1v-4.9h-11
            c-5.2,0-7.8,1.9-7.8,5.8c0,3.8,2.8,6.3,7.6,6.3C976.4,721.3,980.9,718.3,982.1,714.1z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_122_" fill="none" stroke="none" strokeMiterlimit="10" d="M1049,730.1c-4,2.4-9.6,3.7-15.1,3.7
            c-10.9,0.1-18.7-6.3-18.7-17.9v-24h-7.8v-12.2h7.8v-15.5h18.3v15.5h14.5v12.2h-14.5V713c0,4.1,1.9,6,5.1,5.9c1.9,0,4.2-0.7,6.9-1.9
            L1049,730.1z" />
                <motion.path variants={variantService.wordFillWhiteVariant} id="XMLID_124_" fill="none" stroke="none" strokeMiterlimit="10" d="M1110.4,709.9h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C1101.7,676.4,1111.6,689.5,1110.4,709.9z M1092.6,700.1c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H1092.6z"
                />
            </g>
            <g id="XMLID_13_">
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_14_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M839.7,443.3c0,21.6-15.8,36.3-39.3,36.3h-30.5V407
            h31.3C824.1,407,839.7,421.7,839.7,443.3z M820.3,443.5c0-12.5-8.2-21-19.7-21h-11.9v41.8h12.8
            C812.6,464.3,820.3,455.9,820.3,443.5z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_18_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M902,456.5h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C893.3,423,903.3,436.1,902,456.5z M884.2,446.7c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H884.2z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_21_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M931.1,435.6c-3.1,0-5.3,1.1-5.3,3.5
            c0,7.3,29.4,3.8,29.3,23.3c0,11.5-10,18-23.7,18c-9.4,0-18.4-2.7-25.3-8l5.8-11.9c6.4,4.5,13.7,6.9,19.9,6.9c3.4,0,5.9-1.1,5.9-3.4
            c0-7.7-28.9-3.8-28.9-23.2c0-11.7,9.8-18.1,23-18.1c8.1,0,16.2,2.3,23.3,6.5l-6.2,12C942,437.7,935.6,435.6,931.1,435.6z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_25_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M983.4,423.6v56H965v-56H983.4z M982.6,407.9
            c0,4.9-3.5,8.4-8.4,8.4s-8.4-3.5-8.4-8.4c0-5,3.5-8.5,8.4-8.5S982.6,402.9,982.6,407.9z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_28_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1051.1,423.6v51c0,15.8-12.3,25.8-30.4,25.8
            c-9.8,0-18.7-3.1-25-7.2l6.4-12.7c5.1,3.5,11.3,5.5,17.3,5.5c8.4,0,13.5-4.2,13.5-10.8v-6c-3.6,4.8-9,7.3-15.9,7.3
            c-14,0-23.9-10.8-23.9-27c0-15.9,9.6-26.5,23.5-26.5c7,0,12.6,2.7,16.4,7.5v-6.8H1051.1z M1032.7,450c0-7.9-4.6-13.1-11.3-13.1
            c-6.8,0-11.4,5.4-11.4,13.1c0,7.7,4.6,13,11.4,13C1028.2,463,1032.7,457.7,1032.7,450z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_32_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1121.7,444.3v35.3h-18.3v-30
            c0-6.3-3.7-10.2-9.5-10.2c-6.2,0.1-10.2,4.3-11,10.7v29.6h-18.4v-56h18.4v9.1c3.9-6.4,10.4-9.8,18.5-9.8
            C1113.7,422.9,1121.7,431.2,1121.7,444.3z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_35_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M839.7,567.5c0,21.6-15.8,36.3-39.3,36.3h-30.5v-72.6
            h31.3C824.1,531.3,839.7,546,839.7,567.5z M820.3,567.7c0-12.5-8.2-21-19.7-21h-11.9v41.8h12.8
            C812.6,588.5,820.3,580.1,820.3,567.7z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_38_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M902,580.7h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C893.3,547.2,903.3,560.3,902,580.7z M884.2,570.9c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H884.2z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_41_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M921.2,547.8l11.7,38.9l11.8-38.9h18.4l-21,56h-18.9
            l-20.9-56H921.2z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_43_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1020.4,580.7h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C1011.7,547.2,1021.7,560.3,1020.4,580.7z M1002.6,570.9c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H1002.6z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_46_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1048.9,527v76.8h-18.4V527H1048.9z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_48_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1120.1,575.8c0,17.3-12.2,28.7-30.6,28.7
            s-30.5-11.4-30.5-28.7c0-17.2,12.1-28.6,30.5-28.6S1120.1,558.6,1120.1,575.8z M1077.4,576c0,8.4,4.9,14,12,14
            c7.2,0,12.1-5.6,12.1-14c0-8.3-4.9-13.9-12.1-13.9C1082.3,562.1,1077.4,567.7,1077.4,576z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_51_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1191,576.1c0,17.1-10.1,28.4-25.7,28.4
            c-7.1,0-12.9-2.8-16.9-7.9v27.3H1130v-76.1h18.4v7.2c3.9-5.1,9.5-7.9,16.5-7.9C1180.5,547.2,1191,558.7,1191,576.1z M1172.5,575.7
            c0-8.3-4.9-14-12.1-14c-7,0-11.9,5.7-11.9,14s4.9,13.9,11.9,13.9C1167.6,589.5,1172.5,583.9,1172.5,575.7z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_54_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1285.7,607c-3.6-3.5-7-6.7-10.1-9.8
            c-6,4.8-13,7.5-20.5,7.5c-14.3,0-24.8-8.8-24.8-21.1c0-9.7,6.6-15.2,14.3-19.4c-4.3-6.1-6-10.9-6-16c0-10.8,8.1-17.6,20.8-17.6
            c12.3,0,20.9,6.7,20.9,16.4c0,10.1-8.4,15.4-16.9,19.9c2.9,3.3,6.5,7,11,11.4c2-3.4,3.5-7.2,4.8-11.5l11.7,8.5
            c-1.8,4.7-3.9,8.9-6.5,12.6c3.3,3.1,6.8,6.5,10.8,10.1L1285.7,607z M1266,587.9c-5.9-5.8-10.6-10.7-14.4-14.9
            c-3.8,2.5-6.4,5.3-6.4,9.1c0,5.7,4.6,9.5,10.6,9.5C1259.4,591.6,1262.8,590.2,1266,587.9z M1256.4,558c5.4-2.9,9.4-6,9.4-10.9
            c0-3.6-2.5-6.1-6.3-6.1c-4.1,0-6.7,3-6.7,7C1252.8,551,1253.9,554.1,1256.4,558z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_58_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M804.3,671c-11.2,0-19.7,8.8-19.7,20.4
            c0,11.7,8.5,20.6,19.7,20.6c6,0,12.9-3.3,18-8.5l11.1,11.9c-7.8,8.2-19.4,13.5-30.1,13.5c-21.8,0-38.1-15.9-38.1-37.3
            c0-20.9,16.7-36.6,38.8-36.6c10.8,0,22,4.8,29.3,12.4l-11,13.1C817.6,674.7,810.6,671,804.3,671z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_60_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M877.9,671.3v16.9c-10.2-1-17.1,3.5-18,11.3V728
            h-18.4v-56h18.4v9.3C863.7,674.9,870,671.3,877.9,671.3z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_62_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M938.9,704.9h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C930.2,671.4,940.1,684.5,938.9,704.9z M921.1,695.1c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H921.1z"
                />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_65_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M998.2,691.6V728H980v-6.3c-3.6,4.7-9.3,7.1-16.9,7.1
            c-12,0-19.1-7.2-19.1-17.5c0-10.7,7.6-16.8,21.4-17H980v-0.6c0-5.3-3.6-8.4-10.7-8.4c-4.7,0-10.8,1.6-16.8,4.5l-5.1-12.4
            c8.8-3.9,16.9-6.1,26.1-6.1C989.1,671.3,998.1,678.7,998.2,691.6z M980,709.1v-4.9h-11c-5.2,0-7.8,1.9-7.8,5.8
            c0,3.8,2.8,6.3,7.6,6.3C974.4,716.3,978.9,713.3,980,709.1z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_68_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1047,725.1c-4,2.4-9.6,3.7-15.1,3.7
            c-10.9,0.1-18.7-6.3-18.7-17.9v-24h-7.8v-12.2h7.8v-15.5h18.3v15.5h14.5v12.2h-14.5V708c0,4.1,1.9,6,5.1,5.9c1.9,0,4.2-0.7,6.9-1.9
            L1047,725.1z" />
                <motion.path variants={variantService.wordVariant} initial={['hidden', 'notActive']} animate={['visible', 'active']} id="XMLID_70_" fill="none" stroke="#2B2B2B" strokeMiterlimit="10" d="M1108.4,704.9h-38.9c1.8,6.3,6.4,9.8,12.7,9.8
            c4.8,0,9.4-2,13.1-5.7l9.6,9.6c-5.7,6.4-14.1,9.9-24.4,9.9c-18.3,0-29.6-11.3-29.6-28.4c0-17.4,11.7-28.9,29.2-28.9
            C1099.7,671.4,1109.6,684.5,1108.4,704.9z M1090.6,695.1c0-6.4-4.1-10.8-10.4-10.8c-6,0-9.9,4.3-11.2,10.8H1090.6z"
                />
            </g>
        </motion.svg>
        )
    }
