import { motion } from 'framer-motion'
// import { useEffect, useState } from 'react'
import { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'

import { variantService } from '../services/variantFMService'
import { loadProjects } from '../store/actions/portfolioProjectsActions'


export const _AppPortfolios = (props) => {

    const dispatch = useDispatch()

    const projects = useSelector(state => state.portfolioProjectsModule.projects)

    // const [showPortfolios, setShowPortfolios] = useState(false)



    useEffect(() => {
        dispatch(loadProjects())
    }, [dispatch])


    // useEffect(() => {

    //     window.addEventListener('scroll', handleScroll)

    //     return () => {
    //         window.removeEventListener('scroll', handleScroll)
    //     }

    // }, [])


    // const handleScroll = () => {

    //     // console.log(window.pageYOffset);

    //     if (window.pageYOffset > 2300) {
    //         setShowPortfolios(true)
    //     }

    // }

    return (
        // <motion.div className="app-portfolios" variants={variantService.father} initial='hidden' animate={showPortfolios ? 'visible' : 'hidden'} style={{
        //     visibility: `${!showPortfolios ? 'hidden' : 'visible'}`
        // }}>
        <div className="app-portfolios">
            <div className="header">Portfolio Projects</div>

            <div className="content container">
                {/* <motion.h1 className="text-center" variants={variantService.childOne}>Portfolio Projects</motion.h1> */}
                {/* <h1 className="text-center">Portfolio Projects</h1> */}
                {/* <motion.p className="text-center" variants={variantService.childTwo}>Intrested in having you're own portfolio? I can make it. I allways loved making beautiful websites and i can do the same for you . <br /> Anything that you ask , the way that you want it look , I will make the website so that you will be happy about it. <br /> Here are some Portfolios that i had made so far.</motion.p> */}
                <p className="text-center">Intrested in having you're own portfolio? I can make it. I allways loved making beautiful websites and i can do the same for you . <br /> Anything that you ask , the way that you want it look , I will make the website so that you will be happy about it. <br /> Here are some Portfolios that i had made so far.</p>

                <div className="box-holder">
                    {projects.map(project =>
                        <div key={project.name} className="box-placer">
                            <motion.div className="box" initial='visible' whileHover='hidden'>
                                {/* <motion.div className="box" variants={variantService.childThree}> */}

                                <motion.div className="img-holder" variants={variantService.variantImg}>
                                    {/* <motion.div className="img-holder" whileHover={{ opacity: 0 }} initial={{ opacity: 1 }}> */}
                                    <img src={project.imgUrl} alt="" />
                                    <div className="tags">
                                        <div className="tag-holder">
                                            {project.tags.map(tag =>
                                                <div className={`${tag.tagClass} flex auto-center`} key={tag.tId}>
                                                    <div className="tag-txt">{tag.name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                </motion.div>


                                <motion.div className="btn-holder" variants={variantService.variantBtn}>
                                    {/* <motion.div className="btn-holder" whileHover={{ opacity: 1 }} initial={{ opacity: 0 }}> */}
                                    <div className="btn-box">
                                        <div className="btn-box-holder">
                                            <p className="text-center">{project.desc}</p>
                                            <a href={project.link} target="_blank" rel="noreferrer"><button>{project.linkName}</button></a>
                                        </div>
                                    </div>
                                </motion.div>

                            </motion.div>

                        </div>
                    )}

                </div>

            </div>

        </div>
        // </motion.div>
    )
}


const mapDispatchToProps = {
}

export const AppPortfolios = connect(null, mapDispatchToProps)(_AppPortfolios)