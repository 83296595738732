import { useEffect } from 'react'
import { connect, useDispatch, useSelector } from 'react-redux'
import { motion } from 'framer-motion'

import { variantService } from '../services/variantBigProjectsService'
import { loadBigProjects } from '../store/actions/bigProjectsActions'

import { ReactComponent as Paw } from "../assets/icon/paw-black-shape.svg"
import { ReactComponent as Apps } from "../assets/icon/apps.svg"
import { ReactComponent as Instagram } from "../assets/icon/instagram.svg"




export const _AppProjects = (props) => {

    const dispatch = useDispatch()

    const { projects } = useSelector(state => state.bigProjectsModule)

    useEffect(() => {
        dispatch(loadBigProjects())
    }, [dispatch])

    return (
        <div className="app-projects">

            <div className="heading">
                MY BIG PROJECTS
            </div>

            <div className="green">
                <div className="heading">MY BIG PROJECTS</div>
            </div>

            <div className="green2">

                <div className="projects-container">

                    {projects.map(project =>
                        <div key={project.id} className="content">
                            <motion.div className="project" initial='visible' whileHover='hidden'>
                                <motion.div variants={variantService.variantImg} className="img-wrapper flex auto-center"> <a href={project.link} target="_blank" rel="noreferrer"><button>{project.id === "p101" ? <Paw /> : "" || project.id === "p102" ? <Instagram /> : "" || project.id === "p103" ? <Apps /> : ""}</button> </a>  </motion.div>
                                <motion.div variants={variantService.variantDetails} className="details">
                                    <div className={project.id === "p101" ? ` title-pawesome` : "" || project.id === "p102" ? "title-apexgram" : "" || project.id === "p103" ? "title-appsus" : ""}>{project.id === "p101" ? ` PaweSome` : "" || project.id === "p102" ? "Apexgram" : "" || project.id === "p103" ? "APPSUS" : ""}</div>
                                    <div className="tags">
                                        <div className="tag-holder">
                                            {project.tags.map(tag =>
                                                <div className="tag-one flex auto-center" key={tag.tId}>
                                                    <div className="tag-txt">{tag.name}</div>
                                                </div>
                                            )}
                                        </div>
                                    </div>
                                    <div className="para">{project.desc}</div>
                                    <div className="btn flex auto-center">
                                        <a href={project.link} target="_blank" rel="noreferrer"><button>VIEW PROJECT</button></a>
                                    </div>
                                </motion.div>
                            </motion.div>
                        </div>
                    )}

                </div>

                <div className="btn-all-proj flex auto-center">
                    <a href="https://github.com/AndreyDobrinski?tab=repositories" target="_blank" rel="noreferrer"><button>VIEW ALL PROJECTS</button></a>
                </div>

            </div>

        </div>
    )
}




const mapDispatchToProps = {
}

export const AppProjects = connect(null, mapDispatchToProps)(_AppProjects)