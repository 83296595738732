import React from 'react'
import Apexgram from '../assets/video/ApexgramNative.mp4'
import PhoneExample from '../assets/img/phone-example.png'

export default function AppReactNative() {
    return (
        <div className='app-react-native'>
            <div className="header">React Native Projects</div>
            <div className="content container">

                <h2>Apexgram</h2>

                <div className='flex auto-center'>

                    <div className="phone-holder">
                        <img src={PhoneExample} alt="" />
                    </div>

                    <div className='video-holder'>
                        <video controls disablePictureInPicture width="282" height="500">
                            <source src={Apexgram} type="video/mp4" />
                        </video>
                    </div>

                </div>

            </div>
        </div>
    )
}
