import { portfolioProjectsService } from '../../services/portfolioProjectsService';

export function loadProjects() {
  return async (dispatch) => {
    try {
      const projects = await portfolioProjectsService.query();
      dispatch({ type: 'SET_PORTFOLIO_PROJECTS', projects });
    } catch (err) {
      console.log('portfolioProjectsActions: Could not load Projects', err);
    }
  };
}
